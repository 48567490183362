<script setup lang="ts">
import axios from "axios";
import {ref} from "vue";
import StringField from './fields/StringField'

const props = defineProps([
    'type',
    'id',
    'description',
    'keywords'
])

const description = ref({
    title: 'Description',
    value: props.description
})

const keywords = ref({
    title: 'Keywords',
    value: props.keywords
})

const onSubmit = () => {
    axios.post('/api/seo', {
        type: props.type,
        id: props.id,
        description: description.value?.value,
        keywords: keywords.value?.value
    })
}
</script>

<template>
    <div class="block min-w-full mt-4 bg-white rounded-md shadow">
        <div class="bg-gray-200 px-3 py-2 flex">
            <h4>SEO поля</h4>
        </div>
        <div class="p-3">
            <div class="border-b border-gray-100 mt-3 pb-3" >
                <StringField :field.sync="description"/>
            </div>
            <div class="border-b border-gray-100 mt-3 pb-3" >
                <StringField :field.sync="keywords"/>
            </div>
            <div class="flex mt-3">
                <button class="block rounded bg-green-200 px-4 py-2 hover:bg-green-300"
                        @click="onSubmit">Сохранить
                </button>
            </div>
        </div>
        <notifications/>
    </div>
</template>
